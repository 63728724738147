<template>
  <section class="ant-layout site-layout">
    <header class="ant-layout-header site-header">
      <div class="flex-between i-back-normal-head">
        <div class="i-back-head-left">
          <span class="i-back-normal-head-text">消息详情</span>
        </div>
        <div class="i-back-normal-head-right-text"></div>
      </div>
    </header>
    <main class="ant-layout-content main-content">
      <div class="bulletin flex-column">
        <div class="message i-special-card" style="margin: 16px 0;">
          <div class="message-content flex-column" style="overflow: auto;">
            <div class="custom-message-box">
              <div class="custom-message-content-box">
                <div>
                  <div class="title">{{notify.title}}</div>
                  <div class="content" v-html="notify.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
export default {
  name: "bulletin",
  data() {
    return {
       notify: {}
    }
  },
  created(){
    this.getNotifyhDetail();
  },
  methods: {
    getNotifyhDetail(){
       this.$http.get('/api/user/getNotifyhDetail',{id: this.$route.query.id || 0}).then(data=>{
          this.notify = data;
       });
    }
  }
};
</script>
<style scoped>
.bulletin {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>
<style scoped>
.custom-message-box{
  padding: 16px 24px;
}
.custom-message-content-box{
  margin: 0 0 16px 0;
  overflow: auto;
}
.custom-message-content-box > div{
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.custom-message-content-box .title{
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}
.custom-message-content-box .content{
  font-size: 13px;
  color: #555;
}
</style>